import React from "react"
import R from "ramda"
import { Link, graphql } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"

function Practioners(props) {
  console.log(props)
  const { edges } = props.data.allMarkdownRemark

  return (
    <Layout>
      <div className="content px2 py3 mx-auto">
        <SEO
          title={`Practitioners`}
          meta={[
            {
              name: "description",
              content:
                "The Surrey Health and Beauty Spa based in Westcroft Leisure Centre, Carshalton",
            },
            { name: "keywords", content: "surrey, health, beauty, spa" },
          ]}
        />

        <h1 className="h4 caps bold mt0 mb3 center">Practitioners</h1>

        {R.map(
          ({ node }) => (
            <div key={node.id} className="mb3 max-width-2 mx-auto">
              <Link to={node.fields.slug} className="block ">
                <div className="h2 light mb2 gray">
                  {R.path(["frontmatter", "title"], node)}
                </div>
                <div className="gray">
                  {R.path(["frontmatter", "summary"], node)}
                </div>
              </Link>
            </div>
          ),
          edges
        )}
      </div>
    </Layout>
  )
}

export default Practioners

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            summary
          }
        }
      }
    }
  }
`
